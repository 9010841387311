

.trigger {
	background-color: #000;
	opacity:0.25;
	position: absolute;
	cursor: crosshair;
}

.selection {
	background-color: rgba(255, 0, 0, 0.4);
	position : absolute;
}

.selection.delete_rect{
	background-color: rgb(255, 255, 255);
	/*outline: 1px solid rgba(255, 0, 0, 0.4);*/
}

.selection.marker_elypse {
	border-radius: 50%;
	background-color: transparent;
	border: 20px solid rgba(255, 255, 0, 0.4);
}
.selection.marker_rect{
	background-color: rgba(255, 255, 0, 0.4);
}

.holder .selection .x{
	color : #fff;
	position: absolute;
	top: -28px;
	right: 0;
	cursor: pointer;
	background-color: #000;
	width:20px;
	height: 20px;
	text-align: center;
	vertical-align: middle;
	line-height: 20px;
	padding: 2px;
	z-index: 100;
}
.holder .selection .info{
	color : #fff;
	position: absolute;
	right: 25px;
	top: -28px;
	background-color: #000;
	height: 20px;
	width: 150px;
	padding: 2px 5px;
	display: inline-block;
	z-index: 4444;
}

.imageAreaSelector .selection .resizing-handler{
	position: absolute;
	background-color : #000000;
	border : 1px #ffffff solid;
	height : 10px;
	width : 10px;
	cursor: pointer;
	z-index: 5555;
	display: none;
}
.imageAreaSelector .selection.active .resizing-handler{
	display: block;
}

.imageAreaSelector .selection .resizing-handler:hover{
	background-color : #555;
}
.imageAreaSelector .selection .resizing-handler.active{
	background-color : #fff;
}

.imageAreaSelector .selection .resizing-handler.top-left{
	top: -5px;
	left: -5px;
}


.imageAreaSelector .selection .resizing-handler.top-center{
	top: -5px;
	left: 50%;
	margin-left: -5px;
}

.imageAreaSelector .selection .resizing-handler.top-right{
	top: -5px;
	right: -5px;
}

.imageAreaSelector .selection .resizing-handler.center-left{
	top: 50%;
	left: -5px;
	margin-top: -5px;
}

.imageAreaSelector .selection .resizing-handler.center-right{
	top: 50%;
	right: -5px;
	margin-top: -5px;
}

.imageAreaSelector .selection .resizing-handler.bottom-right{
	right: -5px;
	bottom: -5px;
}

.imageAreaSelector .selection .resizing-handler.bottom-center{
	bottom: -5px;
	left: 50%;
	margin-left: -5px;
}


.imageAreaSelector .selection .resizing-handler.bottom-left{
	left: -5px;
	bottom: -5px;
}

.holder{
	margin-top: 70px;
}

.buttons-bar {
	margin-top: -50px;
	height: 50px;
	position: absolute;
}

.buttons-bar .button.active{
	text-decoration: underline;
	background-color: #000;
}
.buttons-bar .button {
	padding: 0px 15px;
	margin-right:5px;
	float:left;
	height: 50px;
	background-color: #888;
	color: #fff;
	text-align: center;
	line-height: 50px;
	cursor: pointer;
}

.holder .button.elypse{
}
