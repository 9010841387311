body {
  padding-top: 60px;
  padding-bottom: 100px; }

.printhelper {
  height: 20px; }

hr {
  margin-top: 5px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #eee; }

@media print {
  body,
  .container,
  .content {
    -webkit-print-color-adjust: exact;
    font-size: 10pt;
    width: 100%;
    margin: 0;
    padding: 0; }
  h1, h2, h3, h4, h5 {
    font-weight: bold; }
  .printhelper {
    height: 10mm; }
  a[href]:after {
    content: none !important; }
  .no-print {
    display: none; } }

/* FOOTER -------------------------------- */
.footer {
  color: #fff;
  background-color: #000;
  text-align: center;
  padding-bottom: 100px; }

@media print {
  .footer {
    display: none; } }

/* MEDIA general -------------------------------- */
.swellmedia.image {
  width: 100%;
  /*padding: 10px;
  background-color: #ff0;
  border: 1px solid #000;*/ }

.swellmedia.image img {
  width: 100%; }

/* ARTICLES INDEX posts in detail view, ready for print -------------------------------- */
.articles.index h2,
.articles.index h3 {
  margin: 5px 0 5px 0;
  font-size: 18px; }

.articles.index .meta {
  margin: 10px 0 5px 0; }

.page.overview .article-item {
  margin: 10px 0 0 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee; }

/* ARTICLES ALL posts in detail view, ready for print -------------------------------- */
.page.all .images {
  width: 100%;
  position: relative; }

.page.all img.swellmedia {
  width: 100%;
  position: relative; }

.page.all .cover-page {
  margin: 0 0 100px 0; }

@media print {
  /* ARTICLES ALL posts in detail view, ready for print -------------------------------- */
  .page.all article,
  .page.all .cover-page {
    page-break-after: always; }
  .page.all .head {
    font-size: 18pt;
    margin-bottom: 5mm; }
  .page.all .head .type {
    float: left; }
  .page.all .head .published_at {
    float: right; }
  .page.all .title,
  .page.all .excerpt {
    font-size: 18pt; }
  .page.all .publisher {
    font-size: 14pt; }
  .page.all .url {
    margin-top: 5mm; }
  .page.all .images {
    margin-top: 5mm; } }

/* PROJECTS posts in detail view, ready for print -------------------------------- */
.page.projects img {
  float: left;
  width: 100px;
  margin-right: 5px; }

/* PROJECTS SHOW posts in detail view, ready for print -------------------------------- */
.page.projects.show .related.articles {
  list-style: none; }
  .page.projects.show .related.articles li {
    margin-bottom: 10px; }
  .page.projects.show .related.articles .publisher .title {
    font-size: 18px;
    font-weight: bold; }

@media print {
  /* PROJECTS INDEX posts in detail view, ready for print -------------------------------- */
  .page.projects .title {
    font-size: 18pt; }
  .page.projects .subtitle {
    font-size: 14pt;
    margin-bottom: 3mm; }
  .page.projects .images {
    margin-top: 10mm; }
  .page.projects .images img {
    width: 30%;
    margin-right: 2%;
    margin-bottom: 2%; }
  .page.projects .cover-page {
    margin-top: 50mm; }
  .page.projects article,
  .page.projects .cover-page {
    page-break-after: always; }
  .page.projects .controls,
  .page.projects .url {
    display: none; } }

/* PUBLISHERS SHOW posts in detail view, ready for print -------------------------------- */
.page.publishers.show .related.articles {
  list-style: none; }
  .page.publishers.show .related.articles li {
    margin-bottom: 10px; }

/*# sourceMappingURL=app.css.map */
